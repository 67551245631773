import React from "react"
import {
  Link,
  // Trans,
  // useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import ShowReel from "../components/showreel"
// import MasonrySection from "../components/masonry-section"
import MasonrySection2 from "../components/masonry-section2"

const IndexPage = () => {
  
  const context = React.useContext(I18nextContext);
  const {t} = useI18next();
    
  return (
    <Layout>
      <SEO title="" description={t("DESCRIPTION")}/>
    
      <ShowReel />
      <MasonrySection2 />
    </Layout>
  )
}

export default IndexPage
