import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Zoom from '@material-ui/core/Zoom';

import styled, { keyframes, css } from "styled-components";
import logo from '../images/FILM_POSITIVE_yellow_logo_for_black_bg.png'

const Container = styled.div`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 0;
display: flex;
align-items: center;
justify-content: center;

`

const pulse = keyframes`
    0 {
        transform: scale(1);
        opacity: 0.75;
    }
    100% {
        transform: scale(3.5);
        opacity: 0;
    }
`;

const Circle = styled.div`
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    width: 12vw;
    height: 230px;
    height: 12vw;
    background: #ffd901;
    border-radius: 50%;
    z-index: -1;
    
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #ffd901;
        border-radius: 50%;
    }
    
    &::after {
        background: #ffd901;
        z-index: 10;
        animation: ${pulse} 1250ms infinite;
    }
`;



const PulsatingCircle = () => {
    
    const [imageLoaded, setImageLoaded] = useState(false)
    
    // const data = useStaticQuery(graphql`
    //     query {
    //       file(relativePath: {eq: "FILM_POSITIVE_yellow_logo_for_black_bg.png"}) {
    //         childImageSharp {
    //           # Specify the image processing specifications right in the query.
    //           # Makes it trivial to update as your page's design changes.
    //           fluid(maxWidth: 1920) {
    //             ...GatsbyImageSharpFluid
    //           }
    //         }
    //       }
    //     }
    // `)
    
    return (
        <Container>
            <Zoom in={imageLoaded}>
                <img src={logo} alt="Film Positive logo" onLoad={()=>setImageLoaded(true)}/>
            </Zoom>
            {/*<Img
                fluid={data.file.childImageSharp.fluid} 
                alt="Film positive Logo"
            />*/}
            <Circle/>
        </Container>
    )
}

export default PulsatingCircle