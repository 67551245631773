import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import overlay from "../images/09.png"
import logo from "../images/film-positive-greyscale-500x.jpg"
import Circle from "../components/pulsating-circle"

import ReactPlayer from 'react-player'


import styled from "styled-components"
import { Settings } from "@material-ui/icons"

const Section = styled.section`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-width: 100%;
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    @media ALL and (max-aspect-ratio: 16/9) { 
        height: auto;
        height: 100%;
    }
`

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .fp-player {
        position: absolute;
        top: 0;
        left: 0;
    }

`


const ShowReel = () => {
    
    const [loading, setLoading] = React.useState(true)

    const hideLoadingEffect = () => {
        setLoading(false)
    }

    const admin = useStaticQuery(graphql`
        query {
            markdownRemark(fileAbsolutePath: {regex: "/(admin)/"}) {
              id
              frontmatter {
                hero_video
              }
            }
          }
    `)


    return(
        <Section>
            <VideoWrapper>
            <ReactPlayer 
                className='fp-player'
                // url='https://vimeo.com/433886072'
                url = {admin.markdownRemark.frontmatter.hero_video}
                playing={true}
                muted
                volume={null}
                // light={true}
                width="100%"
                height="100%" 
                controls={false}
                config={{
                    vimeo: {
                      playerOptions: {
                          loop: true,
                      }
                    },
                }}
                style={{zIndex: 1}}
                onPlay={hideLoadingEffect}
            />
            
                {
                    loading &&
                    <Circle/>
                }
            </VideoWrapper>
        </Section>
    )
}


export default ShowReel