import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';
import Masonry from 'react-masonry-css'
import ReactMasonry from 'react-masonry-component';
import { Reveal, Tween } from 'react-gsap';

import styles from './masonry-section.module.scss'
import styled from "styled-components"


const Section = styled.section`

`


const Container = styled.div`
position: relative;
&:hover {
  img {
    transform: scale(1.2);
  }
}
`

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  
  img {
    transform: scale(1.0);
    transition: all 3s ease-out;
  }
  
  // img:hover {
  //   transform: scale(1.2);
  // }
`

const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 0, 0.4);
  background: rgb(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${Container}:hover & {
    opacity: 1;
    padding: 5
  }
  
    h2 {
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 300;
      text-align: center;
    }
`

const masonryOptions = {
    transitionDuration: 0,
    //columnWidth: '30%',
    itemSelector: '.grid-item',
    percentPosition: true
};
 
const imagesLoadedOptions = { background: '.my-bg-image-el' }
 

const MasonrySection = () => {
  
    const context = React.useContext(I18nextContext);
    
    const {t} = useI18next();
    const language = context.language;
  
    const items = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          sort: {fields: frontmatter___weight, order: DESC}
          filter: { frontmatter: { category: { in: ["filmek", "movies", "commercials", "reklamok"] } } }
        ) {
          edges {
            node {
              frontmatter {
                thumbnail {
                  publicURL
                }
                title
                language
                weight
              }
              fields {
                slug
                category
                pathReference
              }
            }
          }
        }
      }
    `)
    let posts = items.allMarkdownRemark.edges
    let postsFilteredByLanguage = posts.filter(post=>post.node.frontmatter.language.toLowerCase() == language)
    console.log(postsFilteredByLanguage);

    const images = postsFilteredByLanguage.map((edge, key)=> {
      console.log(edge.node.frontmatter.weight, edge.node.frontmatter.title);
      let publicURL
      try {
        publicURL = edge.node.frontmatter.thumbnail.publicURL
      } catch(error) {
        publicURL = ""
      }
      
      return (
          <Container key={key} className="grid-item">
            {/*<Link to={`/${edge.node.fields.category}/${edge.node.fields.slug}`}>*/}
            <Link to={`/${edge.node.fields.category}/${edge.node.fields.pathReference}`}>
              <ImageWrapper >
                <img src={publicURL} width="100%" height="auto" />
              </ImageWrapper>
              <Title><h2>{edge.node.frontmatter.title}</h2></Title>
            </Link>
          </Container>
      )
    })

    return(
        <Section>
           
            <ReactMasonry
              // breakpointCols={3}
              // className="masonry-grid"
              // columnClassName="masonry-grid_column"
              
              className={'masonry-grid'} // default ''
              elementType={'div'} // default 'div'
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
              
                {images}
              
            </ReactMasonry>
            
        </Section>
    )
    
}


export default MasonrySection